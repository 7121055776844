<template>
  <main class="pb-20">
    <router-link
      v-show="hasMicrosite"
      to="/j-site"
      class="flex items-center mt-2 mb-4 text-sm font-bold text-green-700 border-none w-fit"
      data-cy="j-site-create__button-back"
    >
      <JdsIcon
        name="arrow-left"
        size="20px"
        fill="#069550"
        class="w-5 h-5 mr-2"
      />
      Kembali ke Kelola J-Site
    </router-link>

    <section class="bg-white w-full rounded-lg flex flex-col px-5 py-[18px]">
      <h1 class="font-inter font-bold text-[21px] leading-8 mb-8">
        Lengkapi Data Website Anda!
      </h1>

      <ValidationObserver
        v-slot="{ invalid }"
        ref="form"
      >
        <form @submit.prevent="onCreateWeb">
          <!-- File Input -->
          <div class="grid grid-cols-2 gap-12">
            <div class="flex flex-col gap-5 pt-14">
              <div class="flex gap-[30px]">
                <div
                  v-if="favicon.source"
                  class="flex-shrink-0 w-[104px] h-[104px] rounded-full overflow-hidden bg-gray-400"
                >
                  <img
                    width="104"
                    height="104"
                    class="w-full h-full object-contain object-center"
                    :src="favicon.source"
                    alt="Ilustrasi favicon"
                  >
                </div>
                <div
                  v-else
                  class="flex-shrink-0 w-[104px] h-[104px] py-2 rounded-full border-2 border-dashed border-gray-500 bg-gray-100"
                >
                  <div class="flex flex-col items-center justify-center h-full gap-1">
                    <div class="flex justify-center">
                      <JdsIcon
                        name="plus"
                        size="24px"
                        fill="#757575"
                        class="border-[3px] border-gray-600 rounded-full p-1"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <h2 class="font-medium text-gray-900 font-roboto">
                    Favicon
                  </h2>
                  <p class="w-full text-gray-800 text-sm">
                    Ukuran maksimal file adalah 1 MB.
                  </p>
                  <p class="w-full text-gray-800 text-sm">
                    File yang didukung adalah .png dan .svg
                    <br>
                    dengan resolusi maksimal 192 x 192 pixel.
                  </p>
                  <BaseButton
                    v-if="!favicon.source"
                    type="button"
                    class="text-green-700 border-2 border-green-700 text-sm w-[134px] my-4"
                    data-cy="j-site-create__button-upload-favicon"
                    @click="$refs.favicon.click()"
                  >
                    Tambah File
                    <template #icon-right>
                      <JdsIcon
                        name="plus"
                        size="14px"
                        fill="#069550"
                      />
                    </template>
                  </BaseButton>
                  <ValidationProvider
                    v-slot="{ errors }"
                    ref="faviconWrapper"
                    rules="required|ext:png,svg|size:1000|maxdimensions:192,192"
                  >
                    <input
                      id="faviconInput"
                      ref="favicon"
                      type="file"
                      hidden="true"
                      accept="image/png, image/svg+xml"
                      @change="onFaviconChange"
                    >
                    <p
                      class="text-[#D32F2F] text-[13px] leading-[23px]"
                      data-cy="j-site-create__text-error-favicon"
                    >
                      {{ errors[0] }}
                    </p>
                    <div
                      v-if="favicon.filename"
                      class="flex items-center justify-between px-2 py-1 my-4 text-sm font-normal leading-6 text-gray-800 border border-gray-600 rounded-md font-lato"
                    >
                      <span>
                        {{ favicon.filename }}
                      </span>
                      <div class="flex flex-row items-center gap-4 px-4">
                        <BaseButton
                          type="button"
                          class="text-green-600 font-lato text-sm !border-none !h-fit !px-0"
                          @click="onPreviewImage(favicon)"
                        >
                          <JdsIcon
                            name="eye"
                            size="16px"
                          />
                        </BaseButton>
                        <BaseButton
                          type="button"
                          class="text-green-600 font-lato text-sm !border-none !h-fit !px-0"
                          data-cy="j-site-create__button-delete-favicon"
                          @click="handleDelete('favicon')"
                        >
                          <JdsIcon
                            name="trash"
                            size="14px"
                          />
                        </BaseButton>
                      </div>
                    </div>
                    <span
                      v-if="!favicon.filename && !favicon.error && !errors[0]"
                      class="text-sm font-normal leading-6 text-gray-800 font-lato"
                      data-cy="j-site-create__text-empty-favicon"
                    >
                      Belum ada file terpilih.
                    </span>
                    <span
                      v-if="favicon.error"
                      class="text-[#D32F2F] text-[13px] leading-[23px]"
                      data-cy="j-site-create__text-error-favicon"
                    >
                      {{ favicon.error }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="flex gap-[30px]">
                <div
                  v-if="logo.source"
                  class="flex-shrink-0 w-[104px] h-[104px] rounded-full overflow-hidden bg-gray-400"
                >
                  <img
                    width="104"
                    height="104"
                    class="w-full h-full object-contain object-center"
                    :src="logo.source"
                    alt="Ilustrasi logo site"
                  >
                </div>
                <div
                  v-else
                  class="flex-shrink-0 w-[104px] h-[104px] py-2 rounded-full border-2 border-dashed border-gray-500 bg-gray-100"
                >
                  <div class="flex flex-col items-center justify-center h-full gap-1">
                    <div class="flex justify-center">
                      <JdsIcon
                        name="plus"
                        size="24px"
                        fill="#757575"
                        class="border-[3px] border-gray-600 rounded-full p-1"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <h2 class="font-medium text-gray-900 font-roboto">
                    Site Logo
                  </h2>
                  <p class="w-full text-gray-800 text-sm">
                    Ukuran maksimal file adalah 1 MB.
                  </p>
                  <p class="w-full text-gray-800 text-sm">
                    File yang didukung adalah .png dan .svg
                    <br>
                    dengan resolusi maksimal 360 x 360 pixel.
                  </p>
                  <BaseButton
                    v-if="!logo.source"
                    type="button"
                    class="text-green-700 border-2 border-green-700 text-sm w-[134px] my-4"
                    data-cy="j-site-create__button-upload-logo"
                    @click="$refs.logo.click()"
                  >
                    Tambah File
                    <template #icon-right>
                      <JdsIcon
                        name="plus"
                        size="14px"
                        fill="#069550"
                      />
                    </template>
                  </BaseButton>
                  <ValidationProvider
                    v-slot="{ errors }"
                    ref="logoWrapper"
                    rules="required|ext:png,svg|size:1000|maxdimensions:360,360"
                  >
                    <input
                      id="logoInput"
                      ref="logo"
                      type="file"
                      hidden="true"
                      accept="image/png, image/svg+xml"
                      @change="onLogoChange"
                    >
                    <p
                      class="text-[#D32F2F] text-[13px] leading-[23px]"
                      data-cy="j-site-create__text-error-logo"
                    >
                      {{ errors[0] }}
                    </p>
                    <div
                      v-if="logo.filename"
                      class="flex items-center justify-between px-2 py-1 my-4 text-sm font-normal leading-6 text-gray-800 border border-gray-600 rounded-md font-lato"
                    >
                      <span>
                        {{ logo.filename }}
                      </span>
                      <div class="flex flex-row items-center gap-4 px-4">
                        <BaseButton
                          type="button"
                          class="text-green-600 font-lato text-sm !border-none !h-fit !px-0"
                          @click="onPreviewImage(logo)"
                        >
                          <JdsIcon
                            name="eye"
                            size="16px"
                          />
                        </BaseButton>
                        <BaseButton
                          type="button"
                          class="text-green-600 font-lato text-sm !border-none !h-fit !px-0"
                          data-cy="j-site-create__button-delete-logo"
                          @click="handleDelete('logo')"
                        >
                          <JdsIcon
                            name="trash"
                            size="14px"
                          />
                        </BaseButton>
                      </div>
                    </div>
                    <span
                      v-if="!logo.filename && !logo.error && !errors[0]"
                      class="text-sm font-normal leading-6 text-gray-800 font-lato"
                      data-cy="j-site-create__text-empty-logo"
                    >
                      Belum ada file terpilih.
                    </span>
                    <span
                      v-if="logo.error"
                      class="text-[#D32F2F] text-[13px] leading-[23px]"
                      data-cy="j-site-create__text-error-logo"
                    >
                      {{ logo.error }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center w-full h-full">
              <img
                src="@/assets/images/ilustrasi-create-microsite.svg"
                alt="ilustrasi contoh favicon dan site logo"
                width="463"
                height="396"
                class="object-cover object-center max-w-full"
              >
            </div>
          </div>

          <!-- Text Input -->
          <div class="flex flex-col w-full gap-2 my-5">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|max:100|sitename"
              class="flex flex-col"
              vid="name"
            >
              <label
                for="name"
                class="text-gray-900 text-[15px] leading-[23px]"
              >
                Nama Site
              </label>
              <div class="flex flex-col">
                <div
                  :class="{
                    'border rounded-lg overflow-hidden flex items-stretch focus-within:border-green-700': true,
                    'border-gray-500': !errors[0],
                    'border-red-600': errors[0]
                  }"
                >
                  <input
                    id="title"
                    v-model.trim="form.name"
                    data-cy="j-site-create__input-text-site-name"
                    type="text"
                    placeholder="Masukkan nama site anda"
                    class="w-full p-2 text-base leading-4 placeholder:text-gray-600 bg-gray-50 focus:outline-none focus:bg-white"
                  >
                </div>
                <span
                  class="font-lato text-[13px] text-red-600 mt-1 ml-1 first-letter:capitalize"
                  data-cy="j-site-create__text-error-site-name"
                >
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|subdomain|max:35"
              vid="subdomain"
            >
              <label
                for="subdomain"
                class="text-gray-900 text-[15px] leading-[23px] mt-2"
              >
                Nama Site Address
              </label>

              <div class="flex flex-col">
                <div
                  :class="{
                    'w-full h-full relative border rounded-lg overflow-hidden flex items-stretch justify-center leading-[35px] focus-within:border-green-700 bg-gray-50': true,
                    'border-gray-500': !errors[0],
                    'border-red-600': errors[0]
                  }"
                >
                  <div class="flex-none px-2 bg-gray-100 border-r border-gray-300">
                    <label> https:// </label>
                  </div>
                  <input
                    v-model.trim="form.subdomain"
                    data-cy="j-site-create__input-text-site-address"
                    class="w-full px-2 text-base placeholder-gray-600 grow bg-gray-50 focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="Masukkan site address anda"
                  >
                  <div class="flex-none px-2 bg-gray-100 border-l border-gray-300">
                    <label>{{ domainURL }}</label>
                  </div>
                </div>
                <span
                  class="font-lato text-[13px] text-red-600 mt-1 ml-1 first-letter:capitalize"
                  data-cy="j-site-create__text-error-site-address"
                >
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>

          <!-- Action Button -->
          <div class="flex justify-end w-full">
            <BaseButton
              type="submit"
              :disabled="invalid || !isFileUploaded"
              class="bg-green-700 hover:bg-green-600 text-sm text-white w-[179px] mt-4"
              data-cy="j-site-create__button-create-site"
            >
              Buat Website
            </BaseButton>
          </div>
        </form>
      </ValidationObserver>
    </section>

    <!-- Action Prompt -->
    <BaseModal
      :open="modalState"
    >
      <div class="w-full h-full">
        <h1 class="mb-6 text-xl font-medium leading-8 text-green-700 font-roboto">
          {{ modalMessage.title }}
        </h1>
        <p class="text-sm text-gray-800 font-lato">
          {{ modalMessage.message }}
        </p>
      </div>
      <template #footer>
        <div class="flex justify-end gap-4">
          <BaseButton
            class="text-sm text-green-700 border-green-700 hover:bg-green-50"
            @click="modalState = false"
          >
            {{ modalMessage.cancelButton }}
          </BaseButton>
          <BaseButton
            :class="{
              'text-sm text-white bg-red-500 hover:bg-red-400': true,
              '!bg-green-700 hover:!bg-green-400': modalMessage.type === 'create',
            }"
            @click="actionConfirm"
          >
            <p>
              {{ modalMessage.confirmButton }}
            </p>
          </BaseButton>
        </div>
      </template>
    </BaseModal>

    <!-- Success modal -->
    <BaseModal
      :open="showSuccessModal"
    >
      <div class="w-full h-full">
        <div class="flex items-center justify-between mb-6">
          <h1 class="text-xl font-medium leading-8 text-green-700 font-roboto">
            Berhasil!
          </h1>
          <button
            @click="onCloseModal"
          >
            <JdsIcon
              name="times"
              size="20px"
              fill="#424242"
              class="w-5 h-5"
            />
          </button>
        </div>
        <p class="flex items-center text-sm text-gray-800 font-lato">
          <JdsIcon
            name="check-mark-circle"
            size="16px"
            fill="#069550"
            class="w-5 h-5 mr-2"
          />
          Web yang Anda buat berhasil disimpan
        </p>
      </div>
      <template #footer>
        <div class="flex justify-center">
          <BaseButton
            class="text-sm text-white bg-green-700"
            @click="onSuccesCreateSite"
          >
            Mulai Kelola J-Site
          </BaseButton>
        </div>
      </template>
    </BaseModal>

    <!-- Failed modal -->
    <BaseModal
      :open="showFailedModal"
    >
      <div class="w-full h-full">
        <div class="flex items-center justify-end mb-6">
          <button
            @click="showFailedModal = false"
          >
            <JdsIcon
              name="times"
              size="20px"
              fill="#424242"
              class="w-5 h-5"
            />
          </button>
        </div>
        <div class="flex flex-row items-center justify-center gap-4 px-8">
          <div class="bg-[#FFF9E1] h-12 w-12 rounded-full flex justify-center items-center">
            <JdsIcon
              name="warning"
              size="24px"
              fill="#FFA600"
              class="w-6 h-6"
            />
          </div>
          <div class="max-w-[405px] flex flex-col gap-2">
            <h1 class="text-xl font-semibold leading-8 font-roboto">
              {{ modalMessage.title }}
            </h1>
            <p class="text-sm font-normal text-gray-800 font-lato first-letter:capitalize">
              {{ modalMessage.message }}
            </p>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="flex justify-center">
          <BaseButton
            class="text-white bg-green-700"
            @click="showFailedModal = false"
          >
            <p>Saya mengerti</p>
          </BaseButton>
        </div>
      </template>
    </BaseModal>

    <!-- Progress Modal -->
    <ProgressModal
      title="Menyimpan Web"
      message="Mohon tunggu, pembuatan Web sedang diproses."
      :open="showProgressModal"
      :value="progress"
    />

    <!-- Preview image modal -->
    <ImagePreviewWithBackground
      :open="showPreviewImage"
      :source="previewImage.source"
      @close="onClosePreview"
    />
  </main>
</template>

<script>
import '@/common/helpers/vee-validate.js';
import { mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { RepositoryFactory } from '@/repositories/microsite/RepositoryFactory';
import BaseButton from '@/common/components/BaseButton';
import BaseModal from '@/common/components/BaseModal';
import ProgressModal from '@/common/components/ProgressModal';
import ImagePreviewWithBackground from '@/common/components/ImagePreviewWithBackground.vue';

const mediaRepository = RepositoryFactory.get('media');
const settingsRepository = RepositoryFactory.get('settings');

export default {
  name: 'MicrositePage',
  components: {
    BaseButton,
    BaseModal,
    ValidationObserver,
    ValidationProvider,
    ProgressModal,
    ImagePreviewWithBackground,
  },
  data() {
    return {
      favicon: {
        id: '',
        filename: '',
        source: '',
        error: '',
        file: null,
      },
      logo: {
        id: '',
        filename: '',
        source: '',
        error: '',
        file: null,
      },
      form: {
        name: '',
        subdomain: null,
        favicon: '',
        logo: '',
      },
      modalState: false,
      modalMessage: {
        title: '',
        message: '',
        type: '',
      },
      showProgressModal: false,
      showSuccessModal: false,
      showFailedModal: false,
      progress: 20,
      idSite: '',
      domainURL: process.env.VUE_APP_J_SITE_DOMAIN,
      showPreviewImage: false,
      previewImage: {
        filename: '',
        source: '',
      },
    };
  },
  computed: {
    ...mapGetters('microsite', ['hasMicrosite']),
    isFileUploaded() {
      const isFaviconUploaded = !!(this.favicon.source) && !this.favicon.error;
      const isLogoUploaded = !!(this.logo.source) && !this.logo.error;
      return isFaviconUploaded && isLogoUploaded;
    },
  },
  methods: {
    async onFaviconChange(event) {
      this.favicon.error = '';
      const [file] = event.target.files;
      const isValid = await this.$refs.faviconWrapper.validate(file);
      if (!isValid.valid) {
        return;
      }

      this.favicon.source = URL.createObjectURL(file);
      this.favicon.filename = file.name;
      this.favicon.file = file;
    },
    removeFavicon() {
      this.favicon.id = '';
      this.favicon.source = '';
      this.favicon.filename = '';
      this.favicon.error = 'Favicon ini wajib diisi!';
      this.form.favicon = '';
      const faviconElement = document.getElementById('faviconInput');
      faviconElement.value = '';
    },
    async onLogoChange(event) {
      this.logo.error = '';
      const [file] = event.target.files;
      const isValid = await this.$refs.logoWrapper.validate(file);
      if (!isValid.valid) {
        return;
      }

      this.logo.source = URL.createObjectURL(file);
      this.logo.filename = file.name;
      this.logo.file = file;
    },
    removeLogo() {
      this.logo.id = '';
      this.logo.source = '';
      this.logo.filename = '';
      this.logo.error = 'Site Logo ini wajib diisi!';
      this.form.logo = '';
      const faviconElement = document.getElementById('logoInput');
      faviconElement.value = '';
    },
    deleteUploadedImage() {
      if (this.modalMessage.type === 'logo') {
        this.removeLogo();
      } else {
        this.removeFavicon();
      }
    },
    setModalMessage(messageObj) {
      this.modalMessage = { ...messageObj };
    },
    handleDelete(type) {
      this.modalState = true;
      this.setModalMessage({
        title: 'Hapus Gambar',
        message: 'Apakah Anda yakin ingin menghapus gambar ini?',
        cancelButton: 'Batal',
        confirmButton: 'Ya, yakin',
        type,
      });
    },
    actionConfirm() {
      this.modalState = false;
      if (this.modalMessage.type === 'create') {
        this.onSubmit();
      } else {
        this.deleteUploadedImage();
      }
    },
    onCreateWeb() {
      this.modalState = true;
      this.setModalMessage({
        title: 'Buat Web',
        message: 'Apakah Anda ingin membuat Web ini ?',
        cancelButton: 'Batal',
        confirmButton: 'Ya, yakin',
        type: 'create',
      });
    },
    async onUploadFavicon() {
      const formData = new FormData();
      formData.append('file', this.favicon.file);
      formData.append('source', 'local');
      formData.append('directory', 'favicon');
      formData.append('compression', 'false');

      try {
        const response = await mediaRepository.uploadMedia(formData);
        const { data } = response.data;
        this.form.favicon = data?.file?.uri;
        this.favicon.id = data?.id;
      } catch {
        this.removeFavicon();
        this.$toast({
          type: 'error',
          message: 'File favicon gagal diupload',
        });
      }
    },
    async onUploadLogo() {
      const formData = new FormData();
      formData.append('file', this.logo.file);
      formData.append('source', 'local');
      formData.append('directory', 'logo');
      formData.append('compression', 'false');

      try {
        const response = await mediaRepository.uploadMedia(formData);
        const { data } = response.data;
        this.form.logo = data?.file?.uri;
        this.logo.id = data?.id;
      } catch {
        this.removeLogo();
        this.$toast({
          type: 'error',
          message: 'File logo gagal diupload',
        });
      }
    },
    async onSubmit() {
      this.showProgressModal = true;

      try {
        this.progress = 25;
        await this.onUploadFavicon();
        await this.onUploadLogo();

        const response = await settingsRepository.postSite(this.form);
        const { data } = response.data;
        this.idSite = data?.id;
      } catch (error) {
        if (error.response.status === 422) {
          const { errors } = error.response?.data ?? {};

          this.setModalMessage({
            title: 'Oops! Terjadi Kesalahan!',
            message: errors.name || errors.subdomain,
          });
        } else {
          this.setModalMessage({
            title: 'Oops! Pembuatan Website Tidak Berhasil',
            message: 'Sayangnya, upaya Anda untuk membuat website belum berhasil. Kami sarankan Anda mencoba lagi.',
          });
        }
        this.showProgressModal = false;
        this.showFailedModal = true;
        return;
      } finally {
        this.progress = 75;
      }
      setTimeout(() => {
        this.progress = 100;
        setTimeout(() => {
          this.showProgressModal = false;
          this.showSuccessModal = true;
        }, 150);
      }, 150);
    },
    async onSuccesCreateSite() {
      try {
        const response = await this.$store.dispatch('microsite/generateJSiteUrl', this.idSite);
        const { url } = response.data || {};

        if (url) {
          window.location.href = url;
        } else {
          this.$router.push('/j-site');
        }
      } catch (error) {
        // Token Expired
        if (error.response.status === 400) {
          try {
            await this.$store.dispatch('microsite/refreshJSiteToken');

            this.onSuccesCreateSite();
          } catch (err) {
            this.$toast({
              type: 'error',
              message: 'Mohon maaf, terjadi kesalahan. Mohon coba beberapa saat lagi.',
            });

            console.error(err);
          }
        } else {
          this.$toast({
            type: 'error',
            message: 'Gagal membuka halaman kelola J-Site Anda. Mohon coba beberapa saat lagi.',
          });
        }
      }
    },
    onPreviewImage(image) {
      this.showPreviewImage = true;
      this.previewImage.filename = image.filename;
      this.previewImage.source = image.source;
    },
    onClosePreview() {
      this.showPreviewImage = false;
      this.previewImage.filename = '';
      this.previewImage.source = '';
    },
    onCloseModal() {
      if (this.hasMicrosite) {
        this.$router.push('/j-site');
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>
